var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row layouts"
  }, [_c('div', {
    staticClass: "col-md-12 mt-1"
  }, [_c('div', {
    staticClass: "card"
  }, [_vm.isError ? _c('b-row', {
    staticClass: "justify-content-center p-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "10"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": "",
      "dismissible": ""
    }
  }, [_vm._v(" Opps .. Something is wrong ")])], 1)], 1) : _vm._e(), _c('b-row', {
    staticClass: "justify-content-center p-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "10"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "warning",
      "show": "",
      "dismissible": ""
    }
  }, [_vm._v(" If you want to add new product choice, please click name of layout ")])], 1)], 1), _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row justify-content-end"
  }, [_c('label', {
    staticClass: "col-sm-auto",
    attrs: {
      "for": ""
    }
  }, [_vm._v("Count ")]), _c('strong', {
    staticClass: "col-sm-auto"
  }, [_vm._v(_vm._s(_vm.formatPriceBasic(_vm.totalRows)))])]), _c('b-table', {
    attrs: {
      "sticky-header": "500px",
      "responsive": "xl",
      "hover": "",
      "items": _vm.items,
      "busy": _vm.isLoading,
      "isLoading": _vm.isLoading,
      "fields": _vm.fields,
      "show-empty": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + (row.index + 1)) + " ")];
      }
    }, {
      key: "cell(name)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-left"
        }, [_c('router-link', {
          attrs: {
            "to": {
              path: '/layouts/detail/' + row.item.id
            }
          }
        }, [_vm._v(" " + _vm._s(row.item.name) + " ")])], 1)];
      }
    }, {
      key: "cell(Status)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-left"
        }, [row.item.is_active ? _c('span', {
          staticClass: "badge badge-primary"
        }, [_vm._v(" Active ")]) : _vm._e()])];
      }
    }, {
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center text-dark my-2"
        }, [_c('svg', {
          staticClass: "heart",
          attrs: {
            "viewBox": "0 0 32 29.6"
          }
        }, [_c('path', {
          attrs: {
            "d": "M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2\n                  c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"
          }
        })]), _vm._v(" Loading ... ")])];
      },
      proxy: true
    }])
  })], 1), _c('b-row', {
    staticClass: "justify-content-start ml-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "aria-controls": "my-table"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }